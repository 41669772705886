/* eslint no-console:0 */

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"

document.addEventListener('turbo:before-stream-render', function(event) {
  const element = document.getElementById(event.detail.newStream.target)
  if (element.classList.contains('rag')) {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }
})
