import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["modal"]

  open(event) {
    document.getElementById("documents-menu").classList.remove('hidden')
    this.modalOutlet.open()
  }
}
